<template>
  <v-row class="supplier-detail pt-2">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-5 supplier-image text-center" v-if="false">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="image"
          >
          </v-skeleton-loader>
          <template v-else>
            <v-img
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
              aspect-ratio="1"
              max-height="200"
              max-width="200"
              class="margin-auto custom-grey-border custom-border-radius-50"
              transition="fade-transition"
            >
            </v-img>
            <Status :status="supplierActivated"></Status>
          </template>
        </div>
        <div class="col-md-12 px-0 pt-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>
          <table v-else class="width-100">
            <tr>
              <td
                class="py-1 font-size-18 font-weight-500 text-capitalize text-nowrap"
                width="100"
              >
                Supplier #
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <v-skeleton-loader
                  class="custom-skeleton"
                  v-if="pageLoading"
                  type="button"
                >
                </v-skeleton-loader>
                <Barcode v-else :barcode="getBarcode"></Barcode>
              </td>
            </tr>
            <!-- <tr>
              <td class="py-1 font-size-18 text-capitalize font-weight-500">
                Security Code
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                {{ supplier.security_code }}
              </td>
            </tr> -->
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Name
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="fullname">
                  {{ fullname }}
                </template>
                <template v-else><em class="text-muted">No name</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Company
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="supplier.company_name">{{
                  supplier.company_name
                }}</template>
                <template v-else
                  ><em class="text-muted">No company name</em></template
                >
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500 text-nowrap"
              >
                Display Name
              </td>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize"
                v-if="supplier && supplier.display_name"
              >
                {{ supplier.display_name }}
              </td>
              <em v-else class="text-muted"> no display name</em>
            </tr>
            <tr>
              <td valign="top" class="py-1 font-size-18 font-weight-500">
                UEN
              </td>
              <td valign="top" class="py-1 font-size-18 text-lowercase">
                <template v-if="supplier.uen">{{ supplier.uen }}</template>
                <template v-else><em class="text-muted">No uen</em></template>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Website
              </td>
              <td valign="top" class="py-1 font-size-18 text-lowercase">
                <template v-if="supplier.website">{{
                  supplier.website
                }}</template>
                <template v-else
                  ><em class="text-muted">No Website</em></template
                >
              </td>
            </tr>
            <tr>
              <td class="py-1 font-size-18 text-capitalize font-weight-500">
                Category
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <template v-if="supplier.category">
                  <v-chip
                    :color="supplier?.category_color"
                    small
                    label
                    outlined
                    >{{ supplier.category }}</v-chip
                  >
                </template>
                <template v-else
                  ><em class="text--secondary">No category</em></template
                >
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1"
              >
                Contact Details
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Email
              </td>
              <td valign="top" class="py-1 font-size-18">
                <template v-if="supplier && supplier.company_email">
                  {{ supplier.company_email }}
                </template>
                <em v-else class="text-muted"> no email</em>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                Phone No.
              </td>
              <td class="py-1 font-size-18 text-capitalize">
                <template v-if="supplier && supplier.company_number">
                  {{ supplier.company_number }}
                </template>
                <em v-else class="text-muted"> no phone number</em>
              </td>
            </tr>

            <tr>
              <td
                valign="top"
                class="py-1 font-size-18 text-capitalize font-weight-500"
              >
                fax
              </td>
              <td valign="top" class="py-1 font-size-18 text-capitalize">
                <template v-if="supplier.company_fax">{{
                  supplier.company_fax
                }}</template>
                <template v-else><em class="text-muted">No fax</em></template>
              </td>
            </tr>

            <tr v-if="supplier?.tags?.length">
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1"
              >
                Tags
              </th>
            </tr>
            <tr v-if="supplier.tags && supplier.tags.length > 0">
              <td class="font-size-18 py-1" colspan="2">
                <template v-if="supplier.tags && supplier.tags.length > 0">
                  <v-chip
                    small
                    v-for="(row, index) in supplier.tags"
                    :key="index"
                    class="mr-2"
                    text-color="white"
                    :color="row.color"
                    >{{ row.text }}</v-chip
                  >
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
            </tr>
            <!-- <tr>
              <td class="py-1 font-size-18 text-capitalize font-weight-500">
                Remark
              </td>
              <td class="py-1 font-size-18  text-lowercase">
                <template v-if="supplier.supplier_remark">{{
                  supplier.supplier_remark
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >no remark</em
                  ></template
                >
              </td>
            </tr> -->

            <!-- <tr>
              <td class="pb-2 font-size-18 text-capitalize">Group</td>
              <td class="pb-2 font-size-18  text-capitalize">
                <template v-if="supplier.group">{{ supplier.group }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Group</em
                  ></template
                >
              </td>
            </tr> -->
            <tr v-if="false">
              <td valign="top" class="pb-2 font-size-18 text-capitalize">
                Phone
              </td>
              <td valign="top" class="pb-2 font-size-18 text-capitalize">
                <template v-if="supplier.phone">{{ supplier.phone }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Phone</em
                  ></template
                >
              </td>
            </tr>
            <!-- <tr>
              <td class="pb-2 font-size-18 text-capitalize">Website</td>
              <td class="pb-2 font-size-18  text-lowercase">
                <template v-if="supplier.website">{{
                  supplier.website
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Website</em
                  ></template
                >
              </td>
            </tr> -->
          </table>
        </div>
      </div>
    </v-col>
    <v-col class="pt-0 gray-background overflow-x" md="4" sm="12" v-if="false">
      <h5 class="font-weight-500 font-size-18 pt-3">
        <v-skeleton-loader
          class="custom-skeleton"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <span v-else class="color-custom-blue font-weight-600"
          >Contact Details</span
        >
      </h5>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="text@4"
      >
      </v-skeleton-loader>
      <template v-else>
        <table class="width-100">
          <tr v-for="(detail, index) in supplier.details" :key="index">
            <td class="pb-2 font-size-18 text-capitalize">
              {{ getDetailType(detail.type) }}
            </td>
            <td class="pb-2 font-size-18 text-lowercase width-50px">
              ({{ detail.title }})
            </td>
            <td class="pb-2 pl-2 font-size-18 text-lowercase">
              {{ detail.value }}
            </td>
          </tr>
          <template v-if="false">
            <tr>
              <td class="pb-2 font-size-18 text-capitalize font-weight-500">
                Unit No.
              </td>
              <td
                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                colspan="2"
              >
                <template v-if="supplier.unit_number">{{
                  supplier.unit_number
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Unit No.</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize font-weight-500">
                Address Line 1
              </td>
              <td
                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                colspan="2"
              >
                <template v-if="supplier.address_line_1">{{
                  supplier.address_line_1
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Address Line 1</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize font-weight-500">
                Address Line 2
              </td>
              <td
                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                colspan="2"
              >
                <template v-if="supplier.address_line_2">{{
                  supplier.address_line_2
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Address Line 2</em
                  ></template
                >
              </td>
            </tr>
            <tr>
              <td class="pb-2 font-size-18 text-capitalize font-weight-500">
                Postal Code
              </td>
              <td
                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                colspan="2"
              >
                <template v-if="supplier.postal_code">{{
                  supplier.postal_code
                }}</template>
                <template v-else
                  ><em class="text--secondary font-weight-500"
                    >No Postal Code</em
                  ></template
                >
              </td>
            </tr>
          </template>
        </table>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "contact-details",
  title: "Supplier",
  mixins: [CommonMixin],
  props: {
    supplier: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {
    Status,
    Barcode,
  },
  computed: {
    supplierActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.supplier) === false) {
        return _this.supplier.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.supplier &&
        _this.lodash.isEmpty(_this.supplier.persons) === false
      ) {
        for (let i = 0; i < _this.supplier.persons.length; i++) {
          if (_this.supplier.persons[i].default) {
            name =
              _this.lodash.startCase(_this.supplier.persons[i].title) +
              ". " +
              _this.supplier.persons[i].first_name +
              " " +
              _this.supplier.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.supplier &&
        _this.lodash.isEmpty(_this.supplier.details) === false
      ) {
        for (let i = 0; i < _this.supplier.details.length; i++) {
          if (_this.supplier.details[i].type === 1) {
            email = _this.supplier.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.supplier &&
        _this.lodash.isEmpty(_this.supplier.details) === false
      ) {
        for (let i = 0; i < _this.supplier.details.length; i++) {
          if (_this.supplier.details[i].type === 2) {
            phone = _this.supplier.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.supplier.profile_logo) {
        return null;
      }
      return this.supplier.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.supplier.barcode) {
        return "";
      }
      return this.supplier.barcode;
    },
  },
};
</script>
