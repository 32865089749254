<template>
  <v-row class="supplier-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0 px-0">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-row-divider"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td class="font-size-13 text-uppercase font-weight-500">
                Company Address #
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Address
              </td>
            </tr>
          </thead>
          <tbody>
            <template v-if="billingAddress.length > 0">
              <tr
                v-for="(address, index) in billingAddress"
                :key="index"
                :class="{
                  'custom-border-top': index == 0,
                  'orange_lighten-5': index % 2 == 0,
                }"
              >
                <td class="font-size-14 font-weight-500 text-uppercase">
                  <Barcode :barcode="address.barcode"></Barcode>
                </td>
                <td class="font-size-14 font-weight-500 text-capitalize">
                  <div class="d-flex align-baseline">
                    <v-icon small>mdi-map-marker</v-icon>
                    <template v-if="address?.unit_no"
                      >{{ address.unit_no }},</template
                    >
                    <template v-if="address?.street_1"
                      >{{ address.street_1 }},</template
                    >
                    {{ address.street_2 }}
                    <br />
                    {{ address.country }}
                    <template
                      v-if="address?.zip_code && address.zip_code != '000000'"
                    >
                      {{ address?.zip_code }}
                    </template>
                    <!--   {{ address.property_address }},{{ address.country }} -->
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td colspan="2">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no company address at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  name: "billing-address",
  title: "Supplier",
  mixins: [CommonMixin],
  data() {
    return {
      billingAddress: [],
    };
  },
  props: {
    supplier: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Barcode,
  },
  watch: {
    supplier() {
      this.assignData();
    },
  },
  methods: {
    getAddress(address) {
      let addressArr = [];
      if (address.unit) {
        addressArr.push(address.unit);
      }
      if (address.street_1) {
        addressArr.push(address.street_1);
      }
      if (address.street_2) {
        addressArr.push(address.street_2);
      }
      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }
      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }
      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }
      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }
      return addressArr.join(", ");
    },
    assignData() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.supplier.address) === false) {
        let supplierAddress = _this.lodash.map(
          _this.supplier.address,
          function (data) {
            if (data.type === 2) return data;
          }
        );
        _this.billingAddress = _this.lodash.without(supplierAddress, undefined);
      }
    },
  },
  mounted() {
    this.assignData();
  },
};
</script>
